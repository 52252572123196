import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { BASE_URL, PMO_PRODUCTS_LINK, SPM_PRODUCTS_LINK } from '../api/consts';
import {
  html2String,
  useGetPmoProductsQuery,
  useGetSpmProductsQuery,
  WelcomeData,
} from '../api/pages';
import Welcome from './Welcome';

interface ProductsBlockProps {
  title: string;
  link: string;
  items: {
    label: string;
    hash: string;
    image: string;
  }[];
}

const ProductsBlock = ({ title, items, link }: ProductsBlockProps) => {
  return (
    <div className='container'>
      <h2>
        <Link to={link}>{title}</Link>
      </h2>
      <div className='row pt-5 pb-5 service-section justify-content-center'>
        {items.map((product, index) => (
          <div className='col d-flex flex-column justify-content-between' key={index}>
            <p className='mb-1' dangerouslySetInnerHTML={{ __html: product.label }} />
            <Link
              to={{
                pathname: link,
                hash: html2String(product.hash),
              }}
              className='d-block'
            >
              <div className='single-service position-relative' style={{ cursor: 'pointer' }}>
                <img
                  style={{ maxWidth: 130 }}
                  alt={product.label}
                  src={BASE_URL + '/' + product.image}
                />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

interface Props {
  welcomeData: WelcomeData;
  aboutData: string;
}

const Home = ({ welcomeData, aboutData }: Props) => {
  const { data: pmoProducts = [] } = useGetPmoProductsQuery();
  const { data: spmProducts = [] } = useGetSpmProductsQuery();

  return (
    <>
      <Helmet>
        <title>Home - Vinresol</title>
      </Helmet>
      {welcomeData && <Welcome titles={welcomeData} />}
      <div className='bg-white pt-5 pb-5 about-content section-title'>
        {aboutData && <div dangerouslySetInnerHTML={{ __html: aboutData }} className='container' />}
      </div>
      <div className='pt-5'>
        <ProductsBlock
          title='Specialized Pro-resolving Mediators (SPMs)'
          link={SPM_PRODUCTS_LINK}
          items={spmProducts?.map((product) => ({
            label: product.Name,
            hash: product.Name,
            image: product.Image2 ?? '',
          }))}
        />
        <ProductsBlock
          title='Morpholino phosphoramidites (PMOs)'
          link={PMO_PRODUCTS_LINK}
          items={pmoProducts?.map((product) => ({
            label: product.Formula,
            hash: product.Name,
            image: product.Image ?? '',
          }))}
        />
      </div>
    </>
  );
};

export default Home;
