const Footer = () => {
  return (
    <footer className="footer pt-3">
      <div className="container">
        <div className="copy-right d-md-flex justify-content-between">
          <a href="mailto:vinresol@vinresol.com">vinresol@vinresol.com</a>
          <p>© {new Date().getFullYear()} Vinresol. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
