import { html2String, useGetPmoProductsQuery } from '../api/pages';
import { BASE_URL } from '../api/consts';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const formatMoney = (number = 0) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const CellTitle = ({ children }: { children: string | JSX.Element }) => (
  <span className='d-md-none d-inline-block'>{children}</span>
);

const PmoProducts = () => {
  const { hash } = useLocation();
  const { data: products = [] } = useGetPmoProductsQuery();

  useEffect(() => {
    if (hash === '') return;
    const element = document.querySelector(hash);
    element?.scrollIntoView({ block: 'center' });
  }, [hash]);

  return (
    <div className='container'>
      <Helmet>
        <title>Morpholino phosphoramidites (PMOs)</title>
      </Helmet>
      <h1 className='mb-5'>Morpholino phosphoramidites (PMOs)</h1>
      {products.map(({ Name, Image, Prices, Information }, index) => (
        <section id={html2String(Name)} key={index} className='col-12'>
          <article className='product-card'>
            <div className='row'>
              <div className='col-md-5'>
                <h3 className='mb-3' dangerouslySetInnerHTML={{ __html: Name }} />
                <div className='text-center'>
                  {Image && (
                    <img alt={Name} width='auto' height='200' src={`${BASE_URL}/${Image}`} />
                  )}
                </div>
              </div>
              <div className='col-md-7'>
                <div className='price-table'>
                  <div className='row g-0'>
                    <div className='col-md-6 p-2' dangerouslySetInnerHTML={{ __html: Name }} />
                    <div className='col-md-6 d-none d-md-block p-2 text-center'>
                      {Name.split(' ')[1]}
                    </div>
                  </div>
                  <div className='row g-0'>
                    <div className='col-md-2 price-col first'>
                      <div className='fw-bold text-center'>g</div>
                      <div className='text-center mt-2 border-top border-dark'>(USD/g)</div>
                    </div>
                    <div className='col-md-10 prices'>
                      {Prices.map((price) => (
                        <div className='price-col' key={price.price}>
                          <div className='fw-bold'>
                            <CellTitle>g:</CellTitle>
                            {Number(price.volume).toFixed(0)}
                          </div>
                          <div className='mt-2 border-top border-dark'>
                            <CellTitle>
                              <span className='text-nowrap'>(USD/g):</span>
                            </CellTitle>
                            {formatMoney(price.price)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {Information && (
                  <div className='mt-4' dangerouslySetInnerHTML={{ __html: Information }} />
                )}
              </div>
            </div>
          </article>
        </section>
      ))}
    </div>
  );
};

export default PmoProducts;
