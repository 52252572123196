import { WelcomeData } from "../api/pages";

interface Props {
  titles: WelcomeData;
}

const Welcome = ({ titles }: Props) => {
  const { Title1, Title2 } = titles;
  return (
    <div className="image-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="hero-content section-title py-2">
              <div dangerouslySetInnerHTML={{ __html: Title1 }} />
              <div dangerouslySetInnerHTML={{ __html: Title2 }} />
            </div>
          </div>
          <div className="d-none d-md-flex col-md-6">
            <div className="text-end" style={{ marginRight: "-70px" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
