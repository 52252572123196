const NotFound = () => {
  return (
    <div className="container">
      <h1 className="mt-5 mb-5 text-center">404</h1>
      <h5 className="text-center">This page could not be found.</h5>
    </div>
  );
};

export default NotFound;
