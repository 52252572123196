import Layout from './components/Layout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './components/NotFound';
import StaticContent from './components/StaticContent';
import Home from './components/Home';
import SpmProducts from './components/SpmProducts';
import { useGetAboutUsQuery, useGetPagesQuery, useGetWelcomeTitlesQuery } from './api/pages';
import { PMO_PRODUCTS_LINK, SPM_PRODUCTS_LINK } from './api/consts';
import PmoProducts from './components/PmoProducts';

function App() {
  const { isLoading: isPagesLoading, data: pages = [] } = useGetPagesQuery();
  const { isLoading: isWelcomeLoading, data: welcomeData = { Title1: '', Title2: '' } } =
    useGetWelcomeTitlesQuery();
  const { isLoading: isAboutUsLoading, data: aboutData = '' } = useGetAboutUsQuery();

  if (isPagesLoading || isWelcomeLoading || isAboutUsLoading)
    return (
      <div className='vw-100 vh-100 d-flex align-items-center justify-content-center'>
        <div role='status' className='spinner-border'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout pages={pages} />}>
          <Route index element={<Home aboutData={aboutData} welcomeData={welcomeData} />} />
          <Route path={SPM_PRODUCTS_LINK} element={<SpmProducts />} />
          <Route path={PMO_PRODUCTS_LINK} element={<PmoProducts />} />
          {pages.map(({ _id, Link }) => (
            <Route key={_id} path={Link} element={<StaticContent pages={pages} />} />
          ))}
        </Route>
        <Route
          path='*'
          element={
            <Layout pages={pages}>
              <NotFound />
            </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
