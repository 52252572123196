import { useLocation } from "react-router-dom";
import { Page } from "../api/pages";
import { Helmet } from "react-helmet";

const StaticContent = ({ pages }: { pages: Page[] }) => {
  const location = useLocation();
  const currentPage = pages.find(
    ({ Link }) => "/" + Link === location.pathname
  );
  return (
    <div className="container">
      <Helmet>
        <title>{currentPage?.Title} - Vinresol</title>
      </Helmet>
      <h1 className="mb-5">{currentPage?.Title}</h1>
      {currentPage && currentPage?.Content && (
        <div dangerouslySetInnerHTML={{ __html: currentPage.Content }} />
      )}
    </div>
  );
};

export default StaticContent;
