import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Page } from "../api/pages";
import { useScrollHandler } from "../useScrollHandler";
import Footer from "./Footer";
import Header from "./Header";

interface Props {
  pages: Page[];
  children?: JSX.Element | JSX.Element[];
}

const Layout = ({ children, pages }: Props) => {
  const scroll = useScrollHandler();
  const location = useLocation();
  useEffect(() => {
    if (location.hash !== "") return;
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header pages={pages} />
      <section className="hero-section pb-1">
        <Outlet />
        {children}
      </section>
      <Footer />
      {scroll && (
        <div
          className="scroll-top btn-hover d-flex"
          onClick={() => window.scrollTo(0, 0)}
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </div>
      )}
    </>
  );
};

export default Layout;
