import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Page } from "../api/pages";
import logoImgSrc from "../assets/logo.png";
import { useScrollHandler } from "../useScrollHandler";

const Header = ({ pages }: { pages: Page[] }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scroll = useScrollHandler();
  const { pathname } = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname]);

  return (
    <header className="header">
      <div className={classNames("navbar-area", { sticky: scroll })}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <Link
                  to="/"
                  className="navbar-brand d-flex align-items-center"
                  style={{ marginLeft: -20 }}
                >
                  <img height={70} src={logoImgSrc} alt="logo" />
                </Link>
                <button
                  className={classNames("navbar-toggler", {
                    active: isMenuOpen,
                  })}
                  type="button"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>
                <div
                  className={classNames(
                    "collapse navbar-collapse sub-menu-bar",
                    { show: isMenuOpen }
                  )}
                >
                  <ul id="nav" className="navbar-nav ms-auto">
                    {pages.map(({ _id, Link: pageLink, Title }) => (
                      <li key={_id} className="nav-item">
                        <Link
                          className={classNames({
                            active: `/${pageLink}` === pathname,
                          })}
                          to={pageLink[0] === "/" ? pageLink : "/" + pageLink}
                        >
                          {Title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
